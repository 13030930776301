import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background-color: #ccc;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9998;
  opacity: 0.5;
`;

export const Container = styled.div`
  position: fixed;
  width: 100%;
  max-width: 900px;
  min-height: 480px;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  box-shadow: 0 0 6px #0000001a;
  border-radius: 2px;
`;

export const Body = styled.div`
  padding-left: 10px;
  border-left: 1px solid #e26447;
  font-size: 14px;
  position: relative;

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                        & a {
                            color: #BC2E3E!important;
                        }
                        border-left: 1px solid #BC2E3E;
                    `;
      default:
        return `
                    & a {
                        color: #e26447!important;
                    }
                    `;
    }
  }}
`;

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  color: #fff;
  border-radius: 100%;
  position: absolute;
  right: 32px;
  top: 24px;
  cursor: pointer;
  font-size: 30px;
  font-family: Neuzeit Grotesk, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                    background-color:#BC2E3E;
                `;
      default:
        return `
                    background-color:#E26447;
                `;
    }
  }}
  & > span {
    display: block;
    margin: auto;
  }
`;

export const Content = styled.div`
  display: flex;
`;

export const Left = styled.div`
  width: 50%;
  background: #001c72;

  & > img {
    width: 100%;
    margin: 100px 0 90px;
  }
`;

export const Right = styled.div`
  width: 50%;
  display: flex;
  align-items: center;

  & > div {
    margin: 0 32px 0 60px;
    width: 100%;
    align-items: flex-start;
  }
`;

export const BumpOfferInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;

  & .discount-percentage {
    color: #bc2e3e;
  }
`;

export const Title = styled.span`
  font-family: 'Utopia Std', serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 5px;
`;

export const Description = styled.div`
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  margin: 15px 0 20px;

  & > p {
    margin: 0;
  }
`;

export const Disclaimer = styled.div`
  font-family: 'Neuzeit Grotesk', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  & > p {
    margin-top: 16px;
  }
`;

export const CartX = styled.img``;
