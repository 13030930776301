import React, { useContext, useRef, useState } from 'react';
import * as S from './BumpOfferSelectorV3.styles';
import FunnelContext from '../../context/FunnelContext';
import { Image } from '@builder.io/react';
import { CSSTransition } from 'react-transition-group';
import BumpOfferPopup from './BumpOfferPopup';

const BumpOfferSelector = props => {
  const {
    bumpOfferIndex = 0,
    hideBumpOfferDiscount,
    bumpOfferPrefixText,
    hideRibbon,
    hideRrp,
    buttonText,
    buttonTextChecked,
    hideHeading,
    heading,
    learnMoreType,
    learnMoreList,
    banner,
    disclaimer,
    style = `default`,
    children
  } = props;

  const context = useContext(FunnelContext);
  const [listOpened, setListOpened] = useState(false);
  const [popupOpened, setPopupOpened] = useState(false);

  const {
    currency,
    onetime,
    subscription,
    currentCategory,
    pageDesign,
    bumpOfferChecked,
    setBumpOfferChecked,
    setBumpOfferIndex
  } = context;

  const currencySymbol = currency?.symbol || '$';

  const bumpoffers =
    currentCategory === `onetime`
      ? onetime?.bumpoffers
      : subscription?.bumpoffers;

  const handleBumpOffer = () => {
    setBumpOfferChecked(!bumpOfferChecked);
    setBumpOfferIndex(bumpOfferIndex || 0);
  };

  const AddButton = () => (
    <S.Button
      onClick={handleBumpOffer}
      className={bumpOfferChecked ? 'active' : ''}
    >
      {bumpOfferChecked ? (
        <img src="/images/double-check.svg" alt={'✅'} />
      ) : (
        <S.PlusIcon alt={'+'} />
      )}
      {bumpOfferChecked ? (
        <span>&nbsp;{buttonTextChecked}</span>
      ) : (
        <span>&nbsp;{buttonText}</span>
      )}
    </S.Button>
  );

  const LearnMoreButton = ({ handleLearnMore, icon }) => (
    <>
      <S.LearnMoreButton
        onClick={handleLearnMore}
        disableMargin={learnMoreType === 'list' && listOpened}
      >
        <span>{listOpened ? 'Show Less' : 'Learn more'}</span>
        {icon === 'arrow' && (
          <S.ArrowIcon
            src={'/images/fa-chevron-down.svg'}
            listOpened={listOpened}
            alt={listOpened ? '↑' : '↓'}
          />
        )}
        {icon === 'info' && <img src={'/images/info.svg'} alt={'ⓘ'} />}
      </S.LearnMoreButton>
    </>
  );
  const Prices = () => (
    <S.Prices>
      {!hideRrp && (
        <span className={'rrp'}>
          RRP&nbsp;
          {currencySymbol}
          {checkoutData.price.toFixed(2)}{' '}
        </span>
      )}
      <span>
        {currencySymbol}
        {checkoutData.discounted_price.toFixed(2)}{' '}
        {hideBumpOfferDiscount ? (
          ``
        ) : (
          <span className={'discount-percentage'}>
            ({discount_percentage}% Off)
          </span>
        )}
      </span>
    </S.Prices>
  );
  const { checkoutData, discount_percentage } = bumpoffers[bumpOfferIndex || 0];
  const bumpOfferRef = useRef(null);
  const bannerRef = useRef(null);
  const bumpOfferImageRef = useRef(null);

  return (
    <div ref={bumpOfferRef}>
      <S.BumpOfferWrapper pageDesign={pageDesign} className={style}>
        {!hideHeading && <S.Heading>{heading}</S.Heading>}
        <CSSTransition
          in={listOpened && learnMoreType === 'detailed'}
          classNames="banner"
          nodeRef={bannerRef}
          timeout={1500}
          unmountOnExit
        >
          <S.Banner pageDesign={pageDesign} ref={bannerRef}>
            <Image alt="Bump offer" image={banner} />
          </S.Banner>
        </CSSTransition>
        <S.BumpOffer
          hasChildren={children}
          hideRibbon={hideRibbon}
          className={bumpOfferChecked ? 'active' : ''}
          pageDesign={pageDesign}
        >
          <S.BumpOfferLabel pageDesign={pageDesign} className="label">
            <S.BumpOfferContent pageDesign={pageDesign} className="content">
              {children && children}
              {!children && (
                <>
                  <CSSTransition
                    in={!listOpened || learnMoreType !== 'detailed'}
                    classNames="bumpOfferImage"
                    nodeRef={bumpOfferImageRef}
                    timeout={0}
                    unmountOnExit
                  >
                    <S.BumpOfferImage
                      pageDesign={pageDesign}
                      ref={bumpOfferImageRef}
                    >
                      <Image alt="Bump offer" image={checkoutData.image} />
                    </S.BumpOfferImage>
                  </CSSTransition>
                  <S.BumpOfferContentText
                    pageDesign={pageDesign}
                    className="content-text"
                  >
                    {listOpened && learnMoreType === 'detailed' && (
                      <S.BumpOfferInfo>
                        <S.Title>{checkoutData.title.split('-')[0]}</S.Title>
                        <Prices />
                      </S.BumpOfferInfo>
                    )}
                    <S.Description
                      dangerouslySetInnerHTML={{ __html: bumpOfferPrefixText }}
                      textCentered={learnMoreType === 'detailed' && listOpened}
                    />
                    {learnMoreType === 'detailed' && listOpened && (
                      <S.Disclaimer
                        dangerouslySetInnerHTML={{ __html: disclaimer }}
                      />
                    )}
                    {(learnMoreType !== 'detailed' || !listOpened) && (
                      <Prices />
                    )}
                    <S.PopupButtonsWrapper learnMoreType={learnMoreType}>
                      <AddButton />
                      <LearnMoreButton
                        handleLearnMore={() => setPopupOpened(!popupOpened)}
                        icon={'info'}
                      />
                    </S.PopupButtonsWrapper>
                  </S.BumpOfferContentText>
                </>
              )}
            </S.BumpOfferContent>
          </S.BumpOfferLabel>
          <S.ListButtonsWrapper learnMoreType={learnMoreType}>
            <AddButton />
            {learnMoreType === 'list' && listOpened && (
              <S.ListWrapper className="heart-list" showOn={['mobile']}>
                <ul>
                  {learnMoreList.map(item => (
                    <li key={item.text}>{item.text}</li>
                  ))}
                </ul>
              </S.ListWrapper>
            )}
            {learnMoreType !== 'none' && (
              <LearnMoreButton
                handleLearnMore={() => {
                  setListOpened(!listOpened);
                  learnMoreType === 'detailed' &&
                    bumpOfferRef.current.scrollIntoView();
                }}
                icon={'arrow'}
              />
            )}
          </S.ListButtonsWrapper>
          {learnMoreType === 'list' && listOpened && (
            <S.ListWrapper className="heart-list" showOn={['desktop']}>
              <ul>
                {learnMoreList.map(item => (
                  <li key={item.text}>{item.text}</li>
                ))}
              </ul>
            </S.ListWrapper>
          )}
          <BumpOfferPopup
            popupOpened={popupOpened}
            setPopupOpened={setPopupOpened}
            banner={banner}
            title={checkoutData.title.split('-')[0]}
            bumpOfferPrefixText={bumpOfferPrefixText}
            disclaimer={disclaimer}
            prices={<Prices />}
            addButton={<AddButton />}
          />
        </S.BumpOffer>
      </S.BumpOfferWrapper>
    </div>
  );
};

export default BumpOfferSelector;
