import React, { useContext, useEffect, useRef } from 'react';
import * as S from './BumpOfferPopup.styles';
import Modal from '../Modal/Modal';
import FunnelContext from '../../context/FunnelContext';

export const BumpOfferPopup = ({
  popupOpened,
  setPopupOpened,
  banner,
  locked,
  title,
  bumpOfferPrefixText,
  disclaimer,
  prices,
  addButton
}) => {
  const { pageDesign } = useContext(FunnelContext);
  const backdrop = useRef(null);

  useEffect(() => {
    const { current } = backdrop;
    const keyHandler = e =>
      !locked && [27].indexOf(e.which) >= 0 && setPopupOpened(false);

    if (current) {
      window.addEventListener('keyup', e => {
        keyHandler(e);
      });
    }

    return () => {
      window.removeEventListener('keyup', keyHandler);
    };
  });

  if (popupOpened) {
    return (
      <Modal>
        <S.Container pageDesign={pageDesign}>
          <S.CloseButton
            pageDesign={pageDesign}
            onClick={() => setPopupOpened(false)}
          >
            <S.CartX src="/images/fa-cancel.svg" alt="close" />
          </S.CloseButton>
          <S.Content>
            <S.Left>
              <img src={banner} alt={'Bump offer'} />
            </S.Left>
            <S.Right>
              <S.BumpOfferInfo>
                <S.Title>{title}</S.Title>
                {prices}
                <S.Description
                  dangerouslySetInnerHTML={{ __html: bumpOfferPrefixText }}
                />
                {addButton}
                <S.Disclaimer
                  dangerouslySetInnerHTML={{ __html: disclaimer }}
                />
              </S.BumpOfferInfo>
            </S.Right>
          </S.Content>
        </S.Container>
        <S.Wrapper />
      </Modal>
    );
  } else {
    return ``;
  }
};

export default BumpOfferPopup;
